@charset "UTF-8";
h1 {
  font-size: 1.8rem;
}

h2 {
  font-size: 1.5rem;
}

h3 {
  font-size: 1.3rem;
}

h4 {
  font-size: 1.1rem;
}

@media (max-width: 768px) {
  h1 {
    font-size: 1.9rem;
  }

  h2 {
    font-size: 1.2rem;
  }
}
a {
  color: #1d7eb2;
}

a:hover {
  text-decoration: none;
}

.bg-dark {
  background: #0e3f59 !important;
}

.absolute-top {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1080;
}

.btn-primary {
  background: #1d7eb2;
  border-color: #1d7eb2;
  font-size: 0.9rem;
}
.btn-primary:hover {
  background: #165f86;
  border-color: #165f86;
}
.btn-primary:focus {
  background: #196e9c;
  border-color: #196e9c;
}

.breadcrumb {
  font-size: 0.9rem;
  background: none;
}

header.masthead {
  position: relative;
  height: 24rem;
  color: #fff;
  z-index: 0;
}

.navbar-toggler {
  text-align: left;
}
.navbar-toggler i {
  padding: 0.4rem;
  font-size: 26px;
  border: 1px solid #eee;
}

@media (min-width: 992px) {
  .navbar-collapse {
    display: flex !important;
  }

  .navbar-toggler {
    display: none;
  }
}
.masthead-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}
.masthead-image img {
  position: relative;
  object-fit: cover;
  height: 100%;
  width: 100%;
  z-index: 0;
}
.masthead-image::before {
  position: absolute;
  content: "";
  display: block;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 1;
}

header.masthead .masthead-heading {
  z-index: 2;
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  padding-top: 4.5rem;
}

header.masthead .masthead-heading h1 {
  font-family: "Montserrat";
  font-weight: 700;
  line-height: 3.2rem;
  letter-spacing: 0.1rem;
  margin-bottom: 2rem;
}

header.secondhead {
  position: relative;
  padding-top: 10.5rem;
  padding-bottom: 10rem;
  color: #fff;
  background-image: url("/images/header-bg-books.jpg");
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: center center;
  background-size: cover;
  z-index: 0;
}
header.secondhead .inner-form {
  background: #fff;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-align: center;
  align-items: center;
  box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
}
header.secondhead .inner-form .first-wrap {
  width: 200px;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 3px 0px 0 3px;
}
header.secondhead .inner-form .first-wrap .input-select select {
  width: 100%;
  border: none;
  height: 64px;
  color: #888;
  padding: 0.5rem 1.25rem;
  border-radius: 3px 0px 0 3px;
}
header.secondhead .inner-form .first-wrap .input-select select:focus {
  border: none;
  outline: none;
}
header.secondhead .inner-form .second-wrap {
  flex-grow: 1;
}
header.secondhead .inner-form .second-wrap input {
  width: 100%;
  height: 64px;
  border: none;
  color: #888;
  padding: 0.5rem 1.25rem;
}
header.secondhead .inner-form .second-wrap input:focus {
  border: none;
  outline: none;
}
header.secondhead .inner-form .third-wrap {
  width: 80px;
}
header.secondhead .inner-form .third-wrap button {
  cursor: pointer;
  width: 100%;
  height: 64px;
  background: #1d7eb2;
  border: none;
  color: #fff;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  transition: 0.5s;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -ms-transition: 0.5s;
  -o-transition: 0.5s;
}
header.secondhead .inner-form .third-wrap button i {
  font-size: 1.25rem;
}
header.secondhead .inner-form .third-wrap button:hover {
  color: #1d7eb2;
  background: #fff;
}

header.secondhead::before {
  position: absolute;
  display: block;
  content: "";
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: -1;
}

header.secondhead .secondhead-subheading {
  font-size: 2.2rem;
  font-weight: 600;
  line-height: 1.5rem;
  margin-bottom: 25px;
}

#mainNav {
  padding: 1em;
}
#mainNav .logo {
  margin-right: 2rem;
}
#mainNav .logo img {
  max-width: 300px;
  height: auto;
}
@media (max-width: 992px) {
  #mainNav .logo {
    margin-bottom: 1rem;
  }
}
#mainNav .nav-item {
  margin: 0 0.5rem;
}
#mainNav .nav-link {
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: 0.05rem;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
}
#mainNav .anu-link {
  background: #1d7eb2;
  padding-right: 1.25rem;
  padding-left: 1.25rem;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}
#mainNav .anu-link:hover {
  background: #fff;
  color: #1d7eb2;
}
@media (max-width: 992px) {
  #mainNav {
    background: #343a40;
  }
}

#navbarPrimary .nav-item {
  margin-right: 1em;
}
#navbarPrimary .nav-item .nav-link {
  position: relative;
  text-transform: uppercase;
  font-size: 0.85rem;
  font-weight: 500;
  letter-spacing: 0.05em;
  color: #fff;
}
#navbarPrimary .nav-item .nav-link:hover {
  color: #cccccc;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
}
#navbarPrimary .nav-item:not(:last-child) .nav-link::after {
  content: "";
  position: absolute;
  display: block;
  right: -8px;
  top: 50%;
  transform: translateY(-50%);
  height: 4px;
  width: 4px;
  background: #1d7eb2;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
}
@media (max-width: 992px) {
  #navbarPrimary .nav-item:not(:last-child) .nav-link::after {
    display: none;
  }
}
#navbarPrimary .dropdown-menu {
  border: 0;
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

aside .side-menu .nav {
  width: 100%;
}
aside .side-menu .nav li {
  border-bottom: 1px solid #eee;
}
aside .side-menu .nav li a {
  padding: 0.4rem 1rem;
  transition: 0.3s;
  border-radius: 0;
}
aside .side-menu .nav li a i {
  margin-right: 1rem;
}
aside .side-menu .nav li a:hover {
  background: #1d7eb2;
  color: #fff;
}
aside .side-menu .nav .nav-link[data-toggle].collapsed:after {
  content: "▾";
}
aside .side-menu .nav .nav-link[data-toggle]:not(.collapsed):after {
  content: "▴";
}
aside .side-menu .sub-menu ul li a {
  padding: 0.5rem 1rem;
}

.entry {
  display: flex;
  flex-wrap: wrap;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border: 1px solid #eee;
  min-height: 14rem;
  margin: 0 0 2rem 0;
}
.entry__thumb {
  position: relative;
  width: 30%;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.entry__thumb img {
  object-fit: cover;
  height: 100%;
  width: 100%;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.entry__text {
  padding: 1rem;
  width: 70%;
  flex-direction: column;
  display: flex;
  justify-content: space-between;
}
.entry__category a {
  font-size: 0.8rem;
  font-style: italic;
  color: #666;
}
.entry__category a:hover {
  text-decoration: none;
}
.entry__title {
  margin: 0;
  line-height: 1em;
}
.entry__title a {
  font-weight: 500;
}
.entry__title a:hover {
  text-decoration: none;
}
.entry__excerpt p {
  font-size: 0.9rem;
}
.entry__tag {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
@media (max-width: 576px) {
  .entry {
    flex-direction: column;
  }
  .entry__thumb {
    width: 100%;
    height: 10rem;
    border-radius: 5px;
  }
  .entry__thumb img {
    object-fit: cover;
    height: 100%;
    width: 100%;
    border-radius: 5px;
  }
  .entry__text {
    width: 100%;
  }
  .entry__title {
    font-size: 1.5rem;
  }
}

.brick {
  display: flex;
  flex-wrap: wrap;
  margin: 2em 0;
}
.brick__thumb {
  position: relative;
  width: 100%;
  border-radius: 5px;
  margin-right: 3em;
}
.brick__thumb img {
  object-fit: cover;
  height: 100%;
  width: 100%;
  border-radius: 5px;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(0, 0, 0, 0.01);
}
.brick__text {
  width: 100%;
}
.brick__excerpt {
  margin-top: 1em;
  font-size: 0.9rem;
  color: #666;
}
.brick__address, .brick__anchor {
  margin: 0.2rem 0;
}
.brick__address span, .brick__anchor span {
  font-size: 0.8em;
}
.brick__address span i, .brick__anchor span i {
  font-size: 1.2em;
  color: #1d7eb2;
  margin-right: 1em;
}

.badge {
  display: inline-block;
  vertical-align: top;
  padding: 4px 6px;
  background: #f3faea;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  color: #7aba2c;
  margin: 0 3px 3px 0;
  -moz-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  font-weight: 400;
}
.badge:hover {
  background: #7aba2c;
  color: #fff;
}

.book {
  display: flex;
  flex-wrap: wrap;
}
.book__image img {
  object-fit: cover;
  width: 100%;
}
.book__content {
  margin-bottom: 0.9em;
}
.book__tag {
  margin-bottom: 0.5em;
}
.book__text p {
  font-size: 0.875rem;
}
.book__info {
  display: flex;
  flex-direction: column;
}
.book__info-title {
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: 0.05em;
  padding-bottom: 0.4em;
  margin-bottom: 0.8em;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  text-transform: uppercase;
}
.book__info-line {
  margin: 0 0 0.5em 0;
  font-size: 0.9rem;
}
.book__info-line i {
  color: #1d7eb2;
  font-size: 1.1rem;
  margin-right: 1em;
}
.book__map {
  margin-top: 1em;
}
.book__map iframe {
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(0, 0, 0, 0.01);
}
.book__social {
  margin-top: 1em;
}
.book__social a {
  margin-right: 0.5em;
}
.book__social a i {
  font-size: 1.4rem;
}
.book__social-title {
  font-weight: 600;
  font-size: 0.9rem;
  text-transform: uppercase;
  margin-bottom: 0.4em;
  letter-spacing: 0.05rem;
}

.item .item__box:hover .item__title {
  color: #fff;
  text-decoration: none;
}

.item-container {
  background: #f8f8f8;
  padding: 2.8rem 0;
}
.item-container .item {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.item-container .item__box {
  height: 8rem;
  width: 8rem;
  box-shadow: 0 7px 12px 0 rgba(0, 0, 0, 0.03), 0 30px 15px -15px rgba(0, 0, 0, 0.01);
  background: #fff;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1em;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
}
.item-container .item__box:hover {
  background: #1d7eb2;
}
.item-container .item__thumbnail {
  height: 3.2rem;
  width: 3.2rem;
  background: #f8f8f8;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.item-container .item__thumbnail i {
  color: #1d7eb2;
  font-size: 1.5rem;
}
.item-container .item__title {
  font-size: 0.9rem;
  margin-top: 1rem;
  font-weight: 600;
  line-height: 1em;
  color: #1d7eb2;
  line-height: 1em;
  text-align: center;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
}
.item-container .item__title:hover {
  text-decoration: none;
}
@media (max-width: 992px) {
  .item-container {
    display: none;
  }
}

.hero {
  position: relative;
  display: block;
  height: 16rem;
  overflow: hidden;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  z-index: 0;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(0, 0, 0, 0.01);
  margin-bottom: 2em;
}
.hero:hover .hero__thumb img {
  transform: scale(1.2);
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -ms-transform: scale(1.2);
  -o-transform: scale(1.2);
}
.hero:hover .hero__thumb .hero__text {
  transform: translateY(0);
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
}
.hero__thumb {
  height: 100%;
}
.hero__thumb img {
  object-fit: cover;
  height: 100%;
  width: 100%;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
}
.hero__thumb::after {
  content: "";
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0);
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
}
.hero__thumb:hover::after {
  background: rgba(0, 0, 0, 0.3);
}
.hero__text {
  position: absolute;
  height: auto;
  width: 100%;
  bottom: 0;
  z-index: 2;
  display: flex;
  flex-direction: column;
  padding: 1em;
  transform: translateY(calc(100% - 3rem));
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
  -webkit-transform: translateY(calc(100% - 3rem));
  -moz-transform: translateY(calc(100% - 3rem));
  -ms-transform: translateY(calc(100% - 3rem));
  -o-transform: translateY(calc(100% - 3rem));
}
.hero__title {
  color: #fff;
  font-size: 1.2rem;
  line-height: 1em;
  font-weight: 600;
  margin-bottom: 0.5em;
}
.hero__excerpt p {
  color: #fff;
  font-weight: 300;
  font-size: 0.8rem;
}

.post__author {
  display: flex;
  align-items: center;
}
.post__author .byline {
  margin-left: 1em;
  font-size: 0.8rem;
}
.post__author .byline .bytext {
  font-style: italic;
  color: #666;
}
.post__avatar img {
  max-height: 2.8rem;
}
.post__slide {
  height: 22rem;
  margin-bottom: 1.25rem;
}
.post__slide img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}
.post__slide .swiper-container {
  height: 22rem;
}
.post__text h2 {
  font-weight: 600;
}
.post__tags {
  margin-bottom: 2em;
}
.post__body {
  margin-top: 1em;
}
.post__pagination {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.2rem;
}
.post__pagination .prev-nav {
  background: #f8f8f8;
  height: 4rem;
  padding: 0.5rem 1rem;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
  border-bottom: 1px solid #ccc;
  line-height: 1em;
  text-align: center;
}
.post__pagination .prev-nav a {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 0.85rem;
  height: 100%;
}
.post__pagination .prev-nav span {
  text-transform: uppercase;
  font-size: 0.6rem;
}
.post__pagination .prev-nav:hover {
  background: #1d7eb2;
}
.post__pagination .prev-nav:hover a {
  color: #fff;
}
.post__pagination .prev-nav:hover span {
  color: #fff;
}
.post__pagination .next-nav {
  background: #f8f8f8;
  height: 4rem;
  padding: 0.5rem 1rem;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
  line-height: 1em;
  text-align: center;
}
.post__pagination .next-nav a {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 0.85rem;
  height: 100%;
}
.post__pagination .next-nav span {
  text-transform: uppercase;
  font-size: 0.6rem;
}
.post__pagination .next-nav:hover {
  background: #1d7eb2;
}
.post__pagination .next-nav:hover a {
  color: #fff;
}
.post__pagination .next-nav:hover span {
  color: #fff;
}
.post__related {
  margin-top: 2rem;
}

.side-title {
  position: relative;
  font-size: 1.1rem;
  font-weight: 500;
  padding-left: 1.8rem;
  margin-bottom: 0.5em;
}
.side-title::before {
  display: block;
  content: "";
  position: absolute;
  height: 0.1rem;
  width: 0.8rem;
  background: #1d7eb2;
  left: 0.5rem;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
}

.aside__image {
  width: 100%;
  overflow: hidden;
  max-height: 12rem;
}
.aside__image img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}
.aside__text {
  margin-top: 1rem;
  font-size: 0.9rem;
  color: #666;
}

footer {
  background: #f8f8f8;
  padding: 2rem 1rem 0 1rem;
}
footer .footer {
  display: flex;
  flex-wrap: wrap;
}
footer .footer__info {
  position: relative;
  padding: 0 1em 0 0;
  display: flex;
  align-items: center;
  margin-bottom: 1em;
}
@media (max-width: 768px) {
  footer .footer__info {
    flex-direction: column;
  }
}
footer .footer__info img {
  max-height: 5rem;
  margin: 1em;
}
footer .footer__info p {
  font-size: 0.8rem;
  color: #666;
}
footer .footer__info h6 {
  text-transform: uppercase;
  letter-spacing: 0.05em;
  text-decoration: none;
  margin-bottom: 1rem;
  color: #1d7eb2;
}
footer .footer__info::after {
  content: "";
  display: block;
  position: absolute;
  right: 0;
  top: 50%;
  height: 80%;
  width: 1px;
  background: rgba(0, 0, 0, 0.1);
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
}
@media (max-width: 992px) {
  footer .footer__info::after {
    display: none;
  }
}
footer .footer__lastblog {
  margin-bottom: 1em;
}
footer .footer__lastblog h6 {
  margin-bottom: 1rem;
  color: #1d7eb2;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}
footer .footer__lastblog ul {
  list-style-type: none;
  margin: 0;
  padding-inline-start: 1.4em;
}
footer .footer__lastblog ul li {
  line-height: 1em;
  margin-bottom: 0.5rem;
  position: relative;
}
footer .footer__lastblog ul li a {
  font-size: 0.8rem;
  line-height: 1em;
}
footer .footer__lastblog ul li a::before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  height: 4px;
  width: 4px;
  background: #1d7eb2;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
  left: -20px;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
}
footer .footer__lastblog ul li a:hover::before {
  left: -15px;
}
footer .footer__links h6 {
  margin-bottom: 1rem;
  color: #1d7eb2;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}
footer .footer__links ul {
  list-style-type: none;
  margin: 0;
  padding-inline-start: 1.4em;
}
footer .footer__links ul li {
  line-height: 1em;
  margin-bottom: 0.5rem;
  position: relative;
}
footer .footer__links ul li a {
  font-size: 0.8rem;
  line-height: 1em;
}
footer .footer__links ul li a::before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  height: 4px;
  width: 4px;
  background: #1d7eb2;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
  left: -20px;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
}
footer .footer__links ul li a:hover::before {
  left: -15px;
}
footer .container {
  height: 100%;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
}
footer .container .copyright {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  flex-wrap: wrap;
  color: #666;
  font-size: 0.9rem;
  margin-top: 1em;
  padding: 1em 0;
  border-top: 2px solid rgba(0, 0, 0, 0.1);
}
footer .container .copyright a {
  color: #444;
  margin: 0 0.5rem;
}
footer .container .copyright a:hover {
  text-decoration: none;
}

@media all and (min-width: 992px) {
  .navbar .nav-item .dropdown-menu {
    display: none;
  }

  .navbar .nav-item:hover .dropdown-menu {
    display: block;
  }

  .navbar .nav-item .dropdown-menu {
    margin-top: 0;
  }
}